.chooseyelowcontainer {
  background-color: #fed27b;
  height: 70vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem 0rem;
  margin-bottom: 2rem;
}
.containerwidth {
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
.inovationtitleyeloow {
  font-size: 1rem;
  font-weight: 700;
  margin: 0.7rem 0rem;
}
.ytop {
  width: 88%;
  margin: auto;
}
.vectorimg {
  background-image: url("../../Assets/vector3.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: 40vh;
  width: 35%;
  transform: translate(-5rem, 1.5rem);
}
.vectortitle {
  font-size: 2.5rem;
  font-weight: 700;
  width: 48%;
  color: #252d39;
}
.inovationy {
  padding: 1rem;
  z-index: 2;
}
.corerightlefty {
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.bottomy {
  width: 88%;
}

@media (max-width: 768px) {
  .chooseyelowcontainer {
    height: 100%;
    margin-bottom: 0;
  }
  .ytop {
    align-items: center;
    justify-content: center;
  }
  .vectorimg {
    height: 11vh;
    width: 60%;
    transform: translate(0, 0);
  }
  .vectortitle {
    width: 95%;
    font-size: 24px;
    text-align: left;
    /* z-index: 5; */
    display: flex;
    margin: auto;
    text-align: center;
  }
  .containerwidth {
    gap: 0.5rem;
  }
  .bottom {
    width: 100%;
  }
  .corerightlefty {
    flex-direction: column;
    margin-top: 2rem;
  }
  .inovationdesc {
    width: 100%;
  }
}
