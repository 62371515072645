/* .happycontainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
} */
.happycontainer {
  background-color: #fef8ff;
}
.htop {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.hsherotitle > .ourhappy {
  font-size: 3rem;
  font-weight: 700;
  color: #0b62ef;
}

.hsherotitle > .family {
  font-size: 3rem;
  font-weight: 700;
  color: #d7ab21;
}

.hbtitle {
  font-size: 2.5rem;
  font-weight: 700;
  width: 50%;
  text-align: center;
  color: #2e2e2f;
}
.hbottom {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.statastics {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 3rem;
}
.award,
.experience,
.client,
.project {
  align-items: center !important;
  margin-top: 1.5rem;
}
.trophy {
  background-image: url("../../Assets/Trophy.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: 7vh;
  width: 60%;
}
.siconexp {
  background-image: url("../../Assets/Users.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: 7vh;
  width: 60%;
}
.siconhappy {
  background-image: url("../../Assets/Smiley.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: 7vh;
  width: 90%;
}
.siconproject {
  background-image: url("../../Assets/Briefcase.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: 7vh;
  width: 60%;
}
.sdescstats {
  font-size: 1.5rem;
  font-weight: 700;
  color: #2c83d9;
}
@media screen and (max-width: 768px) {
  .industriescontainer {
    padding: 1rem 0;
  }
  .happycontainer {
    text-align: center;
    flex-direction: column;
    padding-bottom: 3rem;
    /* padding-top: 0rem; */
  }
  .hsherotitle {
    margin-top: 1rem;
  }
  .hsherotitle > .ourhappy {
    font-size: 26px;
    font-weight: 700;
    color: #0b62ef;
  }
  .hsherotitle > .family {
    font-size: 26px;
    font-weight: 700;
    color: #d7ab21;
  }
  .hbottom {
    flex-direction: column;
  }
  .hbtitle {
    width: 90%;
    font-size: 20px;
  }
  .statastics {
    display: flex;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 1rem;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 90%;
  }
  .sdesctitle {
    text-align: center;
    font-size: 13px;
  }
}
