.choosecontainer {
  background-color: #fef8ff;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 2rem 0rem;
}
.cherotitle > .why {
  font-size: 2.5rem;
  font-weight: 700;
  color: #0b62ef;
}

.cherotitle > .chooseus {
  font-size: 2.5rem;
  font-weight: 700;
  color: #d7ab21;
}
.cherodesc {
  width: 36%;
  text-align: center;
  margin-top: 1.2rem;
  margin-bottom: 3rem;
  color: #545771;
}
.smalllion {
  width: 2.2rem;
  margin-right: 0.7rem;
}
.bottom {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 90% !important;
  padding-bottom: 4rem;
}
.logoleft {
  background-image: url(../../Assets/logobig.png);
  background-repeat: no-repeat;
  background-size: contain;
  height: 35vh;
  width: 25%;
}
.correct {
  background-image: url("../../Assets/right.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 10%;
  height: 2vh;
}
.leotech {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  margin-left: 6rem;
  color: #1d1f24;
}

.listitemparent {
  display: flex;
  align-items: center;
  color: white;
}
.do {
  color: white;
  font-weight: 700;
  font-size: 1.2rem;
}
.lh {
  line-height: 2.5rem;
  padding: 1.5rem 2.5rem;
  width: 115%;
}

.wrong {
  background-image: url("../../Assets/wrong.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 10%;
  height: 2vh;
}
.othertech {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  margin-left: 8rem;
  color: #1d1f24;
}
.listitemparent {
  display: flex;
  align-items: center;
  color: black;
  font-size: 0.9rem;
  font-weight: 300;
}
.dont {
  color: #1d1f24;

  font-weight: 700;
  font-size: 1.2rem;
}
.lh {
  line-height: 2.5rem;
  padding: 1.5rem 2.5rem;
  width: 120%;
}

.vs {
  width: 3.5rem;
  transform: translateX(0.1rem);
  height: 3rem;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: 3.1rem;
}
.logoright {
  background-image: url(../../Assets/logobig.png);
  background-repeat: no-repeat;
  background-size: contain;
  height: 35vh;
  width: 28%;
  transform: scaleX(-1);
}
@media only screen and (max-width: 768px) {
  .choosecontainer {
    /* padding: 1rem 0; */
    padding: 1rem 0;
    align-items: center;
  }
  .cherotitle > .why {
    font-size: 26px;
    font-weight: 700;
    color: #0b62ef;
  }

  .cherotitle > .chooseus {
    font-size: 26px;
    font-weight: 700;
    color: #d7ab21;
  }

  .leotech {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
    margin-left: 6rem;
    color: #1d1f24;
    display: none;
  }
  .vs {
    display: none;
  }
  .bottom {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  .cherodesc {
    width: 80%;
    text-align: center;
    margin-bottom: 1rem;
  }
  .logoleft,
  .logoright {
    height: 15vh;
    width: 30%;
    display: none;
  }
  .lh {
    width: 100%;
    margin-top: 2rem;
  }
  .vs {
    margin: 2rem 0rem;
  }
  .othertech {
    margin-left: 6.5rem;
    display: none;
  }
}
