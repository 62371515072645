/* .bgcolor {
  background-color: #f9eefb;
  height: 100vh;
}
.herocontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

.herobadge {
  background-color: #ffe9bd;
  border-radius: 1rem;
  width: fit-content;
  padding: 0.2rem 2rem;
}
.herotitle {
  font-size: 3rem;
  font-weight: 600;
  width: 62%;
  margin: 1rem 0rem;
}
.herotitle > .leotech {
  font-weight: 700;
  color: #0b62ef;
}
.herodiscription {
  width: 70%;
}

.herobtn {
  background-color: #0b62e4;
  width: fit-content;
  padding: 0.2rem 2rem;
  border-radius: 1rem;
  margin-top: 1rem;
}
.heroright {
  background-image: url("../../Assets/Heroimg.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 60vh;
  mix-blend-mode: darken;
} */
/* General Styles */
/* header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
} */
.bgcolor {
  /* background-color: #f9eefb; */
  /* background-color: #fce7fd; */
  background-image: url("../../Assets/banner-bg.webp.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 99vh;
  padding-top: 1rem;
}
.heroleft {
  margin-bottom: 8rem;
}
.nav {
  margin-bottom: 0rem !important;
}
.navmenu {
  color: #0b62e4;
  font-weight: 500;
}
.navherobtn {
  background-color: #0b62e4;
  width: fit-content;
  padding: 0.4rem 1rem;
  border-radius: 0.5rem;
  color: white;
  font-weight: 500;
}
.navmenu:hover {
  cursor: pointer;
  color: #0b62e4;
  font-weight: 700;
  border-bottom: 3px solid blue;
}
.herocontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

/* Hero Section Styles */
.herobadge {
  background-color: #ffe9bd;
  border-radius: 1rem;
  width: fit-content;
  padding: 0.2rem 2rem;
  font-weight: 500;
  font-size: 1rem;
}

.herotitle {
  font-size: 3rem;
  font-weight: 700;
  width: 80%;
  margin: 2rem 0rem;
  letter-spacing: 0.2rem;
  line-height: 5rem;
  color: #201e4b;
}

.herotitle > .leotech {
  font-size: 3rem;
  font-weight: 700;
  color: #0b62ef;
  border: 5px solid #0b62e4;
  padding: 0rem 1rem;
  margin-left: 0;
}

.herodescription {
  width: 80%; /* Adjusted to full width for better responsiveness */
  line-height: 2rem;
  color: gray;
}

.herobtn {
  background-color: #0b62e4;
  width: fit-content;
  padding: 0.4rem 2rem;
  border-radius: 0.2rem;
  margin-top: 1rem;
  color: white;
  opacity: 0.7;
  font-weight: 500;
}
.herobtn:hover {
  cursor: pointer;
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

/* Right Section Styles */

/* .heroright {
  background-image: url("../../Assets/Heroimg.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 112%;
  height: 80vh;
  mix-blend-mode: darken;
} */
.heroimggirl {
  mix-blend-mode: darken;
  width: 200vb;
  margin-bottom: 7rem;
}
/* Responsive Design */
@media screen and (max-width: 768px) {
  .bgcolor {
    height: 100%;
    background-color: white;
    padding: 1rem 0rem;
    background-size: contain;
    padding-top: 0rem;
    /* margin-top: 10rem; */
  }
  .herocontainer {
    flex-direction: column-reverse;
    height: auto;
    padding: 0rem 0rem;
    background-color: white;
    width: 100%;
  }

  .heroleft {
    margin-bottom: 0rem;
    width: 84%;
    margin-top: 2rem;
  }
  .herotitle {
    line-height: 3.5rem;
    text-align: center;
    width: 100%;
  }
  .herotitle,
  .herotitle > .leotech {
    font-size: 1.7rem;
    margin-left: 0;
    text-align: left;
    line-height: 2.5rem;
    letter-spacing: 0rem;
    display: none;
  }

  .herobadge {
    width: 70%;
    padding: 0.2rem 1rem;
    text-align: center;
  }
  .herodescription {
    width: 100%;
    line-height: 1.7rem;
    color: gray;
    font-size: 0.9rem;
  }
  .herobtn {
    width: 60%; /* Adjusted to full width for better responsiveness */
    text-align: center;
  }

  /* .heroright {
    height: 42vh;
    margin-right: 1rem;
    width: 100%;
  } */
  .heroimggirl {
    mix-blend-mode: darken;
    height: 45vh;
    width: 50vb;
    margin-left: -1rem;
    display: none;
  }
  .nav {
    width: 100%;
    margin-bottom: 1rem;
  }
  .herotitlemobile {
    font-size: 26px;
    font-weight: 600;
    margin: 1rem 0rem;
  }
  .leotechmobile {
    font-size: 26px;
    font-weight: 700;
    margin: 1rem 0rem;
    color: #0b62ef;
    border: 5px solid #0b62e4;
    padding: 0rem 1rem;
    margin-left: 0.6rem;
  }
}
