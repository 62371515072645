/* .itop {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.sherotitle > .ind {
  font-size: 3rem;
  font-weight: 700;
  color: #0b62ef;
}
.sherotitle > .serve {
  font-size: 3rem;
  font-weight: 700;
  color: #d7ab21;
}
.sdescriptioin {
  width: 39%;
  margin-bottom: 2rem;
}

.bright {
  background-image: url("../../Assets/gradientimg.png");
  height: 50vh;
  width: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  justify-content: space-evenly;
}
.vector {
  background-image: url("../../Assets/vectorsiting.png");
  margin-top: 9rem;
  height: 30vh;
  width: 35%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right bottom;
}
.ibottom {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.sicons {
  display: flex;
  width: 100%;
}
.figma {
  background-image: url("../../Assets/figma.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 5vh;
}
.diamond {
  background-image: url("../../Assets/diamond.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 5vh;
}
.xd {
  background-image: url("../../Assets/xd.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 5vh;
}
.ai {
  background-image: url("../../Assets/AI.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 5vh;
}
.ps {
  background-image: url("../../Assets/PS.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 5vh;
}
.ae {
  background-image: url("../../Assets/AE.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 5vh;
}
.uiux {
  font-size: 2rem;
  color: white;
  font-weight: 600;
}
.list {
  color: white;
} */
/* Base styles for larger screens */

.itop {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.sherotitle > .ind {
  font-size: 2.5rem;
  font-weight: 700;
  color: #0b62ef;
}

.sherotitle > .serve {
  font-size: 2.5rem;
  font-weight: 700;
  color: #d7ab21;
}
.herobadgei {
  background-color: #ffe9bd;
  border-radius: 1rem;
  width: fit-content;
  padding: 0.2rem 2rem;
  font-weight: 500;
  font-size: 1rem;
  color: #8a6901;
  margin-bottom: 0.8rem;
}
.sdescriptioin {
  width: 42%;
  margin-bottom: 2rem;
  margin-top: 0.8rem;
  color: #545771;
}

.bright {
  background-image: url("../../Assets/gradientimg.png");
  height: 63vh;
  width: 75%;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  justify-content: space-evenly;
  /* margin-left: 5rem; */
}
.brleft {
  margin-left: 4rem;
}
.brright {
  margin-left: 6rem;
}
.arrowimagi {
  width: 15%;
  margin-top: 1rem;
}
.arrowimagfirst {
  width: 15%;
  margin-top: 1rem;
}

.vector {
  background-image: url("../../Assets/vectorsiting.png");
  margin-top: 4rem;
  height: 43vh;
  width: 30%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right bottom;
  transform: scale(1.5);
  margin-right: 4rem;
}
.figma {
  background-image: url("../../Assets/figma.png");
}
.diamond {
  background-image: url("../../Assets/diamond.png");
}
.xd {
  background-image: url("../../Assets/xd.png");
}
.ai {
  background-image: url("../../Assets/AI.png");
}
.ps {
  background-image: url("../../Assets/PS.png");
}
.ae {
  background-image: url("../../Assets/AE.png");
}
.ibottom {
  /* display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-left: 3rem; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-left: 3rem; */
  width: 85%;
  margin: auto;
}

.sicons {
  display: flex;
  width: 100%;
}

/* UI/UX Design section */
.sicons > div {
  width: 33.33%;
}

.figma,
.diamond,
.xd,
.ai,
.ps,
.ae {
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 5vh;
}

.uiux {
  font-size: 2rem;
  color: white;
  font-weight: 600;
}

.list {
  color: white;
  line-height: 2.5rem;
  font-weight: 300;
  list-style-type: circle;
  margin-left: 1rem;
}
.lone:hover,
.ltwo:hover,
.lthree:hover,
.lfour:hover,
.lfive:hover,
.lsix:hover {
  background-color: #3e71fe;
  cursor: pointer;
  color: white;
  transition: all 0.3s ease-in-out;
}

/* Media queries for responsiveness */

/* For medium-sized screens */
/* @media screen and (max-width: 1024px) {
  .sdescriptioin {
    width: 50%;
  }

  .bright {
    width: 70%;
  }

  .vector {
    width: 40%;
  }
} */

/* For small screens */
@media screen and (max-width: 768px) {
  .industriescontainer {
    /* padding: 1rem 0; */
    padding: 1rem 0;
  }
  .sherotitle {
    text-align: center;
  }
  .sherotitle > .ind {
    font-size: 27.34px;
    font-weight: 700;
    color: #0b62ef;
  }

  .sherotitle > .serve {
    font-size: 27.34px;
    font-weight: 700;
    color: #d7ab21;
  }
  .herobadgei {
    font-size: 8.75px;
  }
  .sdescriptioin {
    width: 100%;
    text-align: center;
    margin-bottom: 0;
  }

  .itop {
    padding: 1rem;
  }
  .ibottom {
    margin-left: 0;
  }
  .brleft {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 10rem;
  }
  .brleft {
    margin-left: 0rem;
    margin-top: 0;
  }
  .brright {
    margin-left: 0rem;
  }

  .arrowimagi {
    margin: auto;
  }
  .arrowimagfirst {
    margin: auto;
    width: 70%;
  }
  .bright {
    /* margin-top: 0rem;
    width: 100%;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 130vh;
    margin-left: 0rem;
    text-align: center; */
    margin-top: 0rem;
    /* width: 100%; */
    width: 113%;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 130vh;
    margin-left: 0rem;
    text-align: center;
    border-radius: 1rem;
  }

  .vector {
    height: 24vh;
    width: 63%;
    margin: 0;
  }
  .ibottom {
    flex-direction: column;
    margin: auto;
  }
}
