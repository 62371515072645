.aboutcoontainer {
  padding: 3rem 0rem;
  background-color: #fef8ff;
  position: relative;
  z-index: 5;
  overflow-x: hidden;
}
.atop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.aboutbadge {
  background-color: #ffe9bd;
  border-radius: 1rem;
  width: fit-content;
  padding: 0.2rem 4rem;
  color: #8a6901;
  font-weight: 500;
}

.aherotitle {
  font-size: 2.5rem;
  font-weight: 600;
  width: fit-content;
  margin: 0.5rem 0rem;
}

.aherotitle > .about {
  font-size: 2.5rem;
  font-weight: 700;
  color: #0b62ef;
}
.aherotitle > .us {
  font-size: 3rem;
  font-weight: 700;
  color: #d7ab21;
}

.aherodesc {
  width: 50%;
  line-height: 2rem;
  text-align: center;
}

/*  */
.abottom {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.ableft {
  width: 35%;
}
.upperimage {
  background-image: url("../../Assets/os1.png");
  background-size: contain;
  background-repeat: no-repeat;
  /* height: 45vh;
  width: 100%; */
  height: 57vh;
  width: 125%;
}
.lowerimage {
  width: 140%;
}

.lowerleftimg {
  background-image: url("../../Assets/os2.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: 22vh;
  width: 35%;
}

.lowerrightimg {
  background-image: url("../../Assets/os3.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: 22vh;
  width: 75%;
  margin-left: 1rem;
}

.ourstorytitle {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #2e2e2f;
}
.outdesc {
  width: 100%;
  line-height: 1.8rem;
}

.ctoname,
.ceoname {
  font-size: 1rem;
  color: #545751;
  font-weight: 600;
}

.planeicon {
  background-image: url("../../Assets/plane.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: 4vh;
  width: 17%;
}
.saharaicon {
  background-image: url("../../Assets/Star\ 10.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: 4vh;
  width: 17%;
}
@media (max-width: 768px) {
  .aboutcoontainer {
    /* padding: 1.5rem 0rem; */
    padding: 2rem 0rem;
  }
  .abottom {
    flex-direction: column;
  }
  .abright {
    width: 90%;
    margin-bottom: 0;
  }
  .ourstorytitle {
    text-align: left;
    margin-left: 0.8rem;
    font-size: 26px;
  }
  .outdesc {
    font-size: 11px;
    line-height: 1.3rem;
    width: 92%;
    margin: auto;
  }
  .manavshah,
  .darshaksolanki {
    margin-left: 1rem;
    align-items: center;
  }
  .lowerimage {
    height: 15vh;
  }
  .aherodesc {
    text-align: center;
    width: 90%;
    font-size: 11px;
    line-height: 1.2rem;
  }
  .ableft {
    width: 95%;
  }
  .upperimage {
    width: 90%;
    height: 27vh;
    line-height: 1.5rem;
    text-align: center;
    margin: auto;
  }
  .lowerimage {
    width: 90%;
    margin: auto;
    justify-content: space-between;
  }
  .lowerleftimg {
    height: 13vh;
    width: 30%;
  }
  .lowerrightimg {
    width: 65%;
    margin-left: -2rem;
  }
  .misright {
    width: 90%;
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: center; */
  }
  .aherotitle > .about {
    font-size: 26px;
    font-weight: 700;
    color: #0b62ef;
  }
  .aherotitle > .us {
    font-size: 26px;
    font-weight: 700;
    color: #d7ab21;
  }
}
