@import url("https://fonts.googleapis.com/css2?family=Nunito&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Syne:wght@400;500;600;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

/* 
body {
  font-family: Inter, sans-serif;
} */
* {
  scroll-behavior: smooth;
}
body {
  scrollbar-width: none;
  font-family: "Poppins", sans-serif;
  /* overflow-x: hidden; */
}

body::-webkit-scrollbar {
  display: none;
}
