.testimonialcontainer {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}
.previous {
  transform: scaleX(-1);
}
.user1 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 80%;
  min-height: 62vh;
}
.uimage {
  width: 100%;
  height: auto;
  max-height: 20vh;
}
.tuserdesc {
  width: 60%;
  font-size: 1.3rem;
  text-align: center;
  font-weight: 500;
  color: #6a6b82;
  margin-bottom: 4rem;
}
.previous:hover,
.next:hover {
  background-color: orange;
  border-radius: 2rem;
}

@media (max-width: 768px) {
  .testimonialcontainer {
    width: 95%;
  }
  .user1 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 80%;
    min-height: 39vh;
  }
  .tuserdesc {
    width: 95%;
    margin-bottom: 1rem;
    font-size: 10px;
    margin-top: 0.7rem;
  }
  .unameuprof {
    font-size: 10px;
  }
  .uimage {
    width: 100%;
    height: auto;
    max-height: 15vh;
  }
}
