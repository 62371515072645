.footercontainer {
  margin-top: 5rem;
  background-color: #37393f;
  height: 82vh;
  color: white;
}

.footerwidth {
  width: 85%;
  margin: auto;
}
.frlogo {
  max-width: 75%;
  max-height: 80%;
}
.hr {
  border-top: 1px solid gray;
}

.inputrightparent {
  background-color: #474950;
}
.fleft {
  width: 40%;
  justify-content: space-between;
}
.greencolor {
  color: #40ddb6;
}
.input {
  padding: 0.8rem 1rem;
  outline: none;
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.btn {
  background-color: #0b63e5;
  color: white;
  padding: 0.8rem 1rem;
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
@media (max-width: 768px) {
  .footercontainer {
    margin-top: 15rem;
    background-color: #37393f;
    height: 100%;
    color: white;
  }
  .inputbtn {
    flex-direction: column;
    gap: 1rem;
  }
  .btn {
    border-radius: 1rem;
  }
  .footerwidth {
    width: 90%;
    margin: auto;
  }
  .frone {
    flex-direction: column;
    align-items: normal;
    width: 100%;
    margin: auto;
  }
  .frtwo,
  .frthree {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .fleft {
    width: 100%;
  }
  .fright {
    width: 90%;
  }
  .inputrightparent {
    width: 100%;
    margin: auto;
    margin-top: 2rem;
  }
  .follow {
    margin-top: 1rem;
  }
  .locationparent,
  .mailusparent,
  .callusparent,
  .lgoparent {
    margin-bottom: 1rem;
  }
}
