.mainbgformissioncontainer {
  background-color: #fef8ff;
}
.missioncontainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: auto;
}
.listcorrect {
  display: flex;
  align-items: center;
  font-weight: 500;
  color: #545771;
}
.cimage {
  background-image: url("../../Assets/greencorrect.png");
  background-repeat: no-repeat;
  background-size: contain;
  height: 2vh;
  width: 4%;
}
.imageone {
  /* background-image: url("../../Assets/Image\ 1.png");
  background-repeat: no-repeat;
  background-size: contain;
  height: 40vh;
  width: 100%; */
  margin-right: 1rem;
}
/* .imagetwo {
  background-image: url("../../Assets/Image\ 2.png");
  background-repeat: no-repeat;
  background-size: contain;
  height: 40vh;
  width: 100%;
} */
.misleft {
  width: 55%;
}
.misright {
  width: 48%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.missiontitle {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
}
.missiondesc {
  width: 100%;
  line-height: 1.8rem;
  color: #555770;
}
.listcontianer {
  line-height: 2.5rem;
}
.coreleft {
  background-image: url("../../Assets/gradientimg2.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: 60vh;
  width: 32%;
  border-radius: 2rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 2rem;
}
.coretitle {
  font-size: 3rem;
  color: white;
  font-weight: 700;
}
.coredesc {
  font-size: 1.5rem;
  color: white;
  width: 75%;

  text-align: left;
}
.coreright {
  width: 52%;
}
.corerightleft {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 1.5rem;
}
.corerightright {
  margin-left: 1rem;
  gap: 1.5rem;
  transform: translate(0rem, 2rem);
}
.inovation {
  width: 85%;
  padding: 1rem;
  border-radius: 0.7rem;
}
.inovationdesc {
  width: 100%;
  /* color: #545771; */
}

.inovationicon {
  background-image: url("../../Assets/inovationicon.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: 6vh;
  width: 15%;
}
.bugicon {
  background-image: url("../../Assets/bugicon.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: 6vh;
  width: 15%;
}
.databaseicon {
  background-image: url("../../Assets/database.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: 6vh;
  width: 15%;
}
.collabicon {
  background-image: url("../../Assets/collabicon.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: 6vh;
  width: 15%;
}
.inovationtitle {
  font-size: 1.5rem;
  font-weight: 500;
}
.bottomsection {
  background-color: #fef8ff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.boxnoone:hover,
.boxnotwo:hover,
.boxnothree:hover,
.boxnofour:hover {
  background-color: #0b63e5;
  color: white !important;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 768px) {
  .missioncontainer {
    flex-direction: column-reverse;
    width: 100%;
  }
  .misleft {
    width: 90%;
    display: flex;
    flex-direction: column;
  }
  .misright {
    margin-top: 1rem;
    width: 90%;
  }
  .missiontitle {
    text-align: left;
    font-size: 26px;
  }
  .missiondesc {
    text-align: left;
    width: 100%;
    font-size: 11px;
    line-height: 1.3rem;
  }
  .imageone {
    height: 24vh;
  }
  .imagetwo {
    height: 24vh;
    width: 100%;
  }
  .cimage {
    width: 5%;
    height: 1vh;
  }
  .bottomsection {
    flex-direction: column;
  }
  .listcontianer {
    font-size: 12px;
    line-height: 1.5rem;
  }
  .coreleft {
    height: 40vh;
    width: 91%;
  }
  .coreright {
    width: 100%;
    flex-direction: column;
    margin-top: 2rem;
  }
  .corerightright {
    margin-left: 0rem;
    gap: 1.5rem;
    transform: translate(0rem, 2rem);
  }
  .coretitle {
    font-size: 31px;
    color: white;
    font-weight: 600;
  }
  .coredesc {
    font-size: 16px;
    color: white;
    width: 100%;
    text-align: start;
  }
  .inovationtitle {
    font-size: 21px;
    font-weight: 500;
  }
  .inovationdesc {
    width: 100%;
    font-size: 14px;
    /* color: #545771; */
  }
  .corerightleft {
    align-items: center;
  }
  .corerightright {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
