.blueheight {
  display: flex;
  justify-content: space-around;
  background-color: #094fbb;
}

.blueheight {
  height: 47vh;
}
.left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding-left: 11rem; */
  /* margin-left: 4rem; */
  width: 44%;
  text-align: center;
}

.right {
  background-image: url("../../Assets/vectorimg2.png");
  background-repeat: no-repeat;
  background-size: contain;
  height: 50vh;
  width: 77%;
}
.heroformtext {
  width: 93%;
  color: white;
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  line-height: 3.5rem;
}
.inputfield {
  margin-top: 1rem;
  border-radius: 0.8rem;
  padding: 0.8rem 2rem;
  padding-right: 5rem;
  outline: none;
}
.submit {
  margin-top: 1rem;
  padding: 0.8rem 2.5rem;
  color: #0a4fb7;
  background-color: white;
  border-radius: 0.8rem;
  margin-left: 1rem;
  font-weight: 500;
}
.formdata {
  margin-right: 3rem;
}
/* @media screen and (max-width: 768px) {
  .blueheight {
    align-items: center;
    height: auto;
    padding-bottom: 1rem;
    width: 100%;
    margin: auto;
  }
  .heroformtext {
    width: 65%;
    font-size: 14px;
    line-height: 1.5rem;
    text-align: left;
    margin-bottom: 0;
  }
  .left {
    text-align: center;
    padding-left: 0rem;
    align-items: center;
    margin-left: 0;
    width: 50%;
  }
  .formdata {
    display: flex;
    flex-direction: column;
    width: 90%;
  }
  .right {
    width: 50%;
    max-height: 25vh;
  }
  .inputfield {
    margin-top: 1rem;
    border-radius: 0.8rem;
    padding: 0.4rem 1rem;
    outline: none;
  }
  .submit {
    margin-top: 1rem;
    padding: 0.4rem 0rem;
    border-radius: 0.8rem;
    margin-left: 0rem;
    font-weight: 500;
  }
} */
@media screen and (max-width: 768px) {
  .blueheight {
    flex-direction: column-reverse;
    height: 100%;
  }

  .left {
    width: 90%;
    margin: auto;
  }
  .formdata {
    gap: 1rem;
    display: flex;
    justify-content: center;
    align-items: baseline;
    margin-right: 0rem;
  }
  .inputfield {
    margin-top: 1rem;
    border-radius: 0.8rem;
    padding: 0.8rem 2rem;
    /* padding-right: 5rem; */
    outline: none;
    width: 54%;
  }
  .submit {
    margin: 1rem 0rem;
    padding: 0.8rem 1.5rem;
    color: #0a4fb7;
    background-color: white;
    border-radius: 0.8rem;
    margin-left: 0rem;
    font-weight: 500;
  }
  .right {
    background-repeat: no-repeat;
    background-size: cover;
    height: 29vh;
    width: 100%;
    margin: auto;
  }

  .heroformtext {
    width: 80%;
    color: white;
    font-size: 1.2rem;
    font-weight: 600;
    margin: 1rem auto;
    line-height: 2.5rem;
  }
}
