.contactformbg {
  background-image: url("../../Assets/Testimonial/BG.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  flex-direction: column;
}
.cfupper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cftitle {
  font-size: 3.5rem;
  font-weight: 700;
  color: #061d3c;
  margin-top: 3rem;
}
.cfdesc {
  font-weight: 500;
  font-size: 1.3rem;
  width: 55%;
  text-align: center;
  margin-bottom: 2rem;
}
.formcontainer {
  width: 45%;
  padding: 2rem;
}
.cfcfformtitle {
  font-weight: 700;
  font-size: 1.7rem;
  text-align: center;
}
.name,
.email {
  width: 47%;
}
.inputcss {
  border: 1px solid rgb(157, 154, 154);
  padding: 0.5rem 1rem;
  outline: none;
  border-radius: 0.2rem;
}
.msginputcss {
  border: 1px solid rgb(157, 154, 154);
  padding: 1rem 1rem 4rem 1rem;
  outline: none;
  border-radius: 0.2rem;
}
.cfbutton {
  background-color: #0b62ef;
  width: 100%;
  padding: 1rem 0rem;
  border-radius: 0.3rem;
  margin-top: 3rem;
  color: white;
  font-weight: 600;
}

@media (max-width: 768px) {
  .contactformbg {
    background-size: contain;
    width: 100%;
    height: 70vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
  }
  .cfupper {
    margin-top: 6rem;
  }

  .cftitle {
    text-align: center;
    margin-top: 0rem;
    font-size: 1.5rem;
  }
  .cfdesc {
    width: 90%;
    font-size: 0.9rem;
  }
  .formcontainer {
    width: 90%;
    padding: 1rem;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    margin-top: 1rem;
  }
  .cfcfformtitle {
    font-weight: 700;
    font-size: 1.2rem;
    text-align: center;
  }
}
