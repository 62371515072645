.navbartotal {
  width: 70%;
  margin: auto;
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}
.contactbutton {
  background-color: #0b63e5;
  color: white;
  opacity: 0.7;
}
.contactbutton:hover {
  opacity: 1;
  transition: all 0.3s ease-in-out;
}
.logo {
  width: 75%;
}
.mobilemenu {
  width: 73%;
}

@media (max-width: 768px) {
  .navbartotal {
    width: 100%;
    border-radius: 0%;
  }
  .set {
    margin-left: -1rem;
  }
  .mobilemenu {
    margin-top: 23rem;
    background-color: white;
    z-index: 1;
    text-align: center;
    width: 100%;
    margin-left: 0.1;
  }
  .mobilemenuset {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
  .navmenumobile {
    margin-right: 0rem;
  }
}
